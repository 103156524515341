import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    totalContracts: [],
    check:""
};

const contractSlice = createSlice({
    name: "contracts",
    initialState: INITIAL_STATE,
    reducers: {
        setContracts: (state, action) => {
            state.totalContracts = action.payload; // corrected this line
        },
        checkContract:(state)=>{
            state.check = "Hello"
        }
    }
});

export const { setContracts, checkContract } = contractSlice.actions;
export default contractSlice.reducer;
