import React,{useEffect, useState} from 'react'
import BoqHead from './BoqHead'
import BoqTop from './BoqTop'
import BillOfQuantity from './BillOfQuantity'
import { v4 as uuidv4 } from 'uuid';
import {setSendBoq,resetBoq} from '../redux/boq'
import {useDispatch,useSelector} from 'react-redux'
const BoqComponent = () => {
  const [boqArray, setBoqArray] = useState([]);// Boq will be stored on this
  const[sendId,setSendId] = useState()
  const [editData, setEditData] = useState()
  const[activateBoq, setActivateBoq] = useState(false)
  const dispatch = useDispatch()

  const {boq_reset} = useSelector((state)=>state.boq)

  useEffect(() => { 
    if(boq_reset)  {
    setBoqArray([])
    dispatch(setSendBoq([]))  //added newly plse check to clear array
    dispatch(resetBoq(false))
    } 
    
  }, [boq_reset, dispatch]);

useEffect(()=>{
  if(activateBoq){
    dispatch(setSendBoq(boqArray))   
    setActivateBoq(false)
  }
},[activateBoq])

  const deleteBoq = (id) => {
    setBoqArray(boqArray.filter(boq => boq.id !== id));
    setActivateBoq(true)
    };

    const editBoq = (id)=>{
      const boq = boqArray.find((item) => item.id === id);  
      setSendId(id) 
      setEditData(boq)
      }


      const getData = (datas)=>{
        setBoqArray((prev)=>
          [...prev, {
            ...datas,
            id: uuidv4(),
          },])
          setActivateBoq(true)
      }



        const updateContent = (formValues,editId)=>{
        setBoqArray(boqArray.filter(boq => boq.id !== editId));
        setBoqArray((prev)=>
          [...prev, {
            ...formValues,
            id: uuidv4(),
          },])
          setActivateBoq(true)
        }


  return (
    <>
                        <div>
                            <BoqHead sendData={getData} injectData={editData} updateData={updateContent} editId={sendId}/> 
                        </div>

                                
                                <div className='w-auto h-60 border border-stone-300 shadow-lg overflow-y-auto overflow-x-hidden'>
                                      <BoqTop/>
                                        <div>
                                            {boqArray.map((boq, index) => (
                                                <div  key={index} className='w-[28rem] border border-stone-200'>
                                                  <BillOfQuantity sl_no={index+1} {...boq} onDelete={() => deleteBoq(boq.id)} onEdit={()=>editBoq(boq.id)}/>
                                                </div> 
                                                ))}
                                        </div>                                                           
                                </div> 
    </>
  )
}

export default BoqComponent