import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const PrivateRouteApp = ({children}) => {
    const { contractEnable } = useContext(AuthContext);

   if(contractEnable){
        return children;     //This section prevents from url direct routing
    }
  return <Navigate to="/" />;
}

export default PrivateRouteApp