import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const PrivateRoute = ({ children, role }) => {
    const { isAuthenticated, userRole } = useContext(AuthContext);

    if (!isAuthenticated || (role && userRole !== role)) { //isAuthenticated Received from AuthContext, this sets to true when 
        return <Navigate to="/" />;                                 //UserId password matches
       
    }

    return children;
};

export default PrivateRoute;