import React from 'react'

const BoqTop = () => {
  return (
    <>
                                <div className='flex font-semibold sticky top-0 bg-white z-10'>
                                <div className='w-12 lg:h-6 md:h-6 sm:h-8 xs:h-8 border border-stone-200 text-xs text-stone-700 text-center'>Sl no:</div>
                                <div className='w-20  lg:h-6 md:h-6 sm:h-8 xs:h-8 border border-stone-200 text-xs text-stone-700 text-center'>Device Type</div>
                                <div className='w-20  lg:h-6 md:h-6 sm:h-8 xs:h-8 border border-stone-200 text-xs text-stone-700 text-center'>Device Sl no:</div>
                                <div className='w-20  lg:h-6 md:h-6 sm:h-8 xs:h-8 border border-stone-200 text-xs text-stone-700 text-center'>Location</div>
                                <div className='w-20  lg:h-6 md:h-6 sm:h-8 xs:h-8 border border-stone-200 text-xs text-stone-700 text-center'>Remarks</div>
                                <div className='w-20  lg:h-6 md:h-6 sm:h-8 xs:h-8 border border-stone-200 text-xs text-stone-700 text-center'>edit/del</div>
                              </div>
    </>
  )
}

export default BoqTop