import React,{useContext, useEffect, useState, useRef} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from './AuthContext';
import 'react-datepicker/dist/react-datepicker.css';
import {useSelector,useDispatch} from 'react-redux'
import { FaCalendarAlt } from 'react-icons/fa';
import BillOfQuantity from './BillOfQuantity';
import BoqHead from './BoqHead';
import InputContract from './InputContract';
import BoqTop from './BoqTop';
import { setMainHeads } from '../redux/admin';
import axios from 'axios';
import {setButtonStatus,deactivateAll,setAdminActive,setTicketsActive,setContractsActive,setBoqActive,setSettingsActive} from '../redux/admin';
import {setSendBoq, resetBoq} from '../redux/boq';
import { v4 as uuidv4 } from 'uuid';
import SidePanel from './SidePanel';
import UserSettings from './settings/UserSettings';
import BoqComponent from './BoqComponent';


const AdminPage = () => {
  const [boqArray, setBoqArray] = useState([]);// Boq will be stored on this
  // const [editData, setEditData] = useState()
  // const[sendId,setSendId] = useState()
  const [selectState, setSelectState] = useState({
    user_id: "",
    password: "",
    reset_user_id:false
  });
  const[totalContract, setTotalContract] = useState(0)
  const[clearBoqFields, setClearBoqFields] = useState(false)


const boqSend = useSelector((state)=>state.boq)

const contractRef = useRef() 
const bpRef = useRef() 
const contactPersonRef = useRef() 
const emailRef = useRef()
const endUserRef = useRef()
const startDateRef = useRef()
const endDateRef = useRef()
const typeRef = useRef()
const locationRef = useRef()
const userIdRef = useRef()
const passwordRef = useRef()

/*useEffect(()=>{
  if (boqSend) {
    // Update boqArray when boqSend changes
    setBoqArray((prevArray) => [...prevArray, ...boqSend.boqSend]);
    console.log("The received Boq in Admin",boqSend.boqSend);
  }
 // 
},[boqSend])*/

useEffect(() => {
  dispatch(deactivateAll())
  dispatch(setAdminActive());
  dispatch(resetBoq(true))
  const callTotalContract = async () => {
    try {
      const response = await axios.get('https://www.therettermann.com/insert_contract.php');
      const { total_contracts } = response.data;
      setTotalContract(total_contracts);
      setClearBoqFields(false)
    } catch (error) {
      toast.error("Contract Fetching Failed");
    }
    
  };

  callTotalContract();
}, []);


//const {setButton} =  useSelector((state)=>state.admin)

const dispatch = useDispatch()
const navigate = useNavigate()
const {routeContract} = useContext(AuthContext);


const handleContracts = ()=>{
dispatch(setButtonStatus(true))
 routeContract()
 navigate('/contracts');
}

const handleSelectStateChange = (newSelectState) => {
  setSelectState(newSelectState);
};



  

      const handleInputClick = async() => {        

      const inputField = {
        contract_no:contractRef.current.value,
        bp_name:bpRef.current.value,
        contact_person:contactPersonRef.current.value,
        email_id:emailRef.current.value,
        end_user:endUserRef.current.value,
        start_date:startDateRef.current.value,
        end_date:endDateRef.current.value, 
        sla_type:typeRef.current.value,
        location:locationRef.current.value,
        user_id:userIdRef.current.value,
        password: passwordRef.current.value,
        boq:boqSend.boqSend
        }  
        
       if((inputField.contract_no.trim()!=="")||(inputField.bp_name.trim()!=="")||(inputField.email_id.trim()!=="")
        ||(inputField.end_user.trim()!=="")||(inputField.start_date.trim()!=="")||(inputField.end_date.trim()!=="")
        ||(inputField.sla_type.trim()!=="")||(inputField.location.trim()!=="")||(inputField.user_id.trim()!=="")
        ||(inputField.password.trim()!==""))
      
          {

        try {
          
          const response = await axios.post('https://www.therettermann.com/insert_contract.php',inputField);
          const { status, message, total_contracts } = response.data;          
          console.log("fetching try",response.data );
          dispatch(setSendBoq([]))
          
          if (status === "success") {
            

            toast.success(message);
            setTotalContract(total_contracts)
            dispatch(setMainHeads(inputField));
            contractRef.current.value = ""
            bpRef.current.value = ""
            contactPersonRef.current.value = ""
            emailRef.current.value = ""
            endUserRef.current.value = ""
            startDateRef.current.value = ""
            endDateRef.current.value = ""
            typeRef.current.value = ""
            locationRef.current.value = ""   
            userIdRef.current.value = ""
            passwordRef.current.value = ""
           // setBoqArray([]);
            dispatch(resetBoq(true))
           //setClearBoqFields(true)
           //resetBoqArrayRef()
    
            setSelectState((prev) => ({
              ...prev,
              reset_user_id: true
            }));
  
            } else {                
                toast.error(message);
            }
  
  
          } catch (error) {
            toast.error("Failed to connect"); 
            }      

        }
       else{
         toast.error("Fill the input fields");
        return         
        }

      //  dispatch(resetBoq(true))

    };


  return (
    <>
    <div className='w-full flex flex-col lg:flex-row md:flex-row sm:flex-col overflow-x-hidden'> 

      <SidePanel/>
       
            <div className='relative lg:ml-28 sm:ml-0 md:ml-28 mx-auto lg:w-auto lg:h-[100hv] md:w-auto md:h-[35rem] sm:w-[25rem] sm:h-[70rem] xs:w-[25rem] xs:h-[70rem] border border-stone-200  shadow-lg'>

              
              <div className='flex flex-wrap lg:mt-2 md:mt-2 sm:mt-20  xs:mt-20'> 
            
                      <InputContract ref={contractRef} label='Contract No:' type='text' name='contractNo'/>
                      <InputContract ref={bpRef} label='Business Partner Name:' type='text' name='bpname'/>
                      <InputContract ref={contactPersonRef} label='Contact Person' type='name' name='contactperson'/>
                      <InputContract ref={emailRef} label='Email Address' type='email' name='email'/>
                      <InputContract ref={endUserRef} label='End User' type='name' name='enduser'/>
                      <InputContract ref={startDateRef} label='Contract Start Date' type='date' name='startdate'/>
                      <InputContract ref={endDateRef} label='Contract Expiry Date:' type='date' name='endDate'/>
                      <InputContract ref={typeRef} label='SLA Type' type='text' name='slaType'/>   
                      <InputContract ref={locationRef} label='Prime Location' type='text' name='location'/> 
                      <div className=' text-xs font-poppins pt-2 ml-4 font-semibold'>User id:
                        <input type='text' name='user_id' ref={userIdRef} className='lg:w-44 lg:h-7 md:w-44 md:h-7 sm:w-44 sm:h-8 border border-stone-300 rounded-sm pl-2 mt-6 font-poppins mb-1 text-xs'/>
                      </div>

                      <div className=' text-xs font-poppins pt-2 ml-4 font-semibold'>Password:
                        <input type='text' name='user_id' ref={passwordRef} className='lg:w-44 lg:h-7 md:w-44 md:h-7 sm:w-44 sm:h-8 border border-stone-300 rounded-sm pl-2 mt-6 font-poppins mb-1 text-xs'/>
                      </div>
                    
                      {/* <UserSettings  onSelectStateChange={handleSelectStateChange} onIdChange={selectState.reset_user_id} />  */}
              

                      <div className='w-full h-72 mt-2 border border-stone-200 shadow-lg'>
                      <div className='text-[#172554] font-poppins text-lg mt-2 ml-4'>BOQ Entry</div>

                        <div className='flex lg:flex-row md:flex-row sm:flex-col w-auto'> 


                            <BoqComponent  />
                        

                          {/* <div>
                            <BoqHead sendData={getData} injectData={editData} updateData={updateContent} editId={sendId}/> 
                          </div>

                                
                                <div className='w-auto h-60 border border-stone-300 shadow-lg overflow-y-auto overflow-x-hidden'>
                                      <BoqTop/>
                                        <div>
                                            {boqArray.map((boq, index) => (
                                                <div  key={index} className='w-[28rem] border border-stone-200'>
                                                  <BillOfQuantity sl_no={index+1} {...boq} onDelete={() => deleteBoq(boq.id)} onEdit={()=>editBoq(boq.id)}/>
                                                </div> 
                                                ))}
                                        </div>                                                           
                                </div>  */}

                             <div>

                                <div className='w-auto flex flex-row  h-60 lg:ml-4 md:ml-4 sm:ml-0 lg:mt-0 md:mt-0 sm:mt-4 xs:mt-4'>

                                  <div className='flex flex-col'>

                                <div className='flex flex-row lg:w-56 md:w-56 sm:w-56 h-24 border border-stone-300 rounded-sm'>

                                  <div className='text-blue-950 font-poppins font-semibold flex items-center mx-auto pl-4'>No: of Contracts</div>
                                      <div className='w-24 h-24 bg-blue-950 rounded-sm ml-auto flex items-center justify-center overflow-hidden'>
                                        <span className='text-white font-semibold text-3xl font-poppins'>
                                          {totalContract}
                                        </span>
                                      </div>

                                </div>
                               
                                          <div className='w-auto h-14 flex flex-row ml-4'>
                                            <button className='mt-4 mr-4 lg:w-28 lg:h-8 md:w-28 md:h-8 sm:w-28 sm:h-10 xs:w-28 xs:h-10 mb-4 bg-blue-950 rounded-md font-poppins text-xs text-white' onClick={handleInputClick}>Save Contract</button>
                                            <button className='mt-4 mr-4 lg:w-36 lg:h-8 md:w-36 md:h-8 sm:w-36 sm:h-10 xs:w-28 xs:h-10 mb-4  border border-blue-950 rounded-md font-poppins text-xs text-blue-950' onClick={handleContracts}>View/edit Contracts</button>
                                          </div>
                                      </div>
   
                              </div> 

                              </div>



                        </div>


             



                    </div>

              </div>             

            </div>
            <ToastContainer />   
        
        
    </div>

   
    
    </>
  )
}

export default AdminPage