import React,{useState, useEffect} from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import CustomerField from './components/CustomerField'
import LoginAdmin from './components/LoginAdmin'
import Signup from './components/Signup'
import AdminPage from './components/AdminPage';
import { AuthProvider } from './components/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import PrivateRouteApp from './components/PrivateRouteApp';

import axios from 'axios';
import User from './components/User';
import Contracts from './components/Contracts';


const App = () => {




  const [authenticated, setAuthenticated] = useState(false);


  return (
    <>  
   <AuthProvider>

          <BrowserRouter>
            <Routes>
                <Route path="/" element={<User />}/>
                <Route path="/client" element={<PrivateRoute><CustomerField /></PrivateRoute>}/>
                <Route path="/admin" element={<PrivateRoute role="admin"><AdminPage /></PrivateRoute>}/> 
                <Route path="/contracts" element={<PrivateRouteApp><Contracts /></PrivateRouteApp>}/>                   
            </Routes>
          </BrowserRouter>

     </AuthProvider> 
     </>
  )
}
export default App
