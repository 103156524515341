import React, { useEffect, useRef, useState } from 'react'
import SidePanel from './SidePanel'
import ContractHead from './ContractHead'
import ContractDatas from './ContractDatas'
import InputContract from './InputContract'
import { useSelector } from 'react-redux'
import UserSettings from './settings/UserSettings'
import BoqComponent from './BoqComponent'

const Contracts = () => {
  const [selectState, setSelectState] = useState({
    user_id: "",
    password: "",
    reset_user_id:false
  });

  const [rowNumber, setRowNumber]= useState({
    row_select:null,
    row_background:false
  })
  const [inputValue, setInputValue] = useState({
    contract_no:'',
    bp_name:'',
    contact_person:'',
    email_id:'',
    end_user:'',
   contract_st_dt:'',
   contract_end_dt:'',
   sla_type:'',
   prime_loc:'',
   user_id:'',
   pswd:''
  });

const contractRef = useRef() 
const bpRef = useRef() 
const contactPersonRef = useRef() 
const emailRef = useRef()
const endUserRef = useRef()
const startDateRef = useRef()
const endDateRef = useRef()
const typeRef = useRef()
const locationRef = useRef()
const userIdRef = useRef()
const passwordRef = useRef()

const {totalContracts} = useSelector((state)=>state.contracts)

const handleSelectStateChange = (newSelectState) => {
  setSelectState(newSelectState);
};


const editInputField =(e)=>{
  const { name, value } = e.target;
  setInputValue({
   ...inputValue,
  [name]:value
   })
}

const catchRow = (row_num)=>{
setRowNumber((prev)=>{  
  return{
    ...prev,
    row_select:row_num,
    row_background:true
  }
})



setInputValue((prev)=>{
  return{
    ...prev,
    contract_no:totalContracts[row_num-1].contract_no,
    bp_name:totalContracts[row_num-1].bp_name,
    contact_person:totalContracts[row_num-1].contact_person,
    email_id:totalContracts[row_num-1].email_id,
    end_user:totalContracts[row_num-1].end_user,
    contract_st_dt:totalContracts[row_num-1].start_date,
    contract_end_dt:totalContracts[row_num-1].end_date,
    sla_type:totalContracts[row_num-1].sla_type,
    prime_loc:totalContracts[row_num-1].location,
    user_id:totalContracts[row_num-1].user_id,
    pswd:totalContracts[row_num-1].password
  }
})

//setInputValue(totalContracts[row_num-1].bp_name);

}



  return (
    <>
    <div className='w-full flex flex-col lg:flex-row md:flex-row sm:flex-col overflow-x-hidden'> 

<SidePanel />
<div className='flex flex-col'>
    <div className=' font-poppins text-lg lg:ml-32 md:ml-32 sm:ml-2 text-blue-950 lg:mt-0 md:mt-0 sm:mt-24 '>Contract List</div>


<div className='flex lg:flex-row md:flex-row sm:flex-col'>
    <div className='flex lg:flex-col md:flex-col sm:flex-row'>
    <ContractHead />

        <div className="resize-y  border border-gray-300 bg-gray-100 box-border w-auto
                      lg:h-20 md:h-20 sm:h-[16.5rem] lg:mt-0 md:mt-0 sm:mt-2
                      flex lg:flex-col md:flex-col sm:flex-row
                      lg:overflow-y-auto lg:overflow-x-hidden 
                      md:overflow-y-auto md:overflow-x-hidden  
                      sm:overflow-x-auto sm:overflow-y-hidden"
          >
            {totalContracts.map((items, index) => (
              <div key={index} className={`hover:bg-stone-200 ${rowNumber.row_select === index + 1 ? 'border border-stone-500' : ''} `}>
                <ContractDatas sl_no={index + 1} {...items} selectedRow={catchRow} />
              </div>
            ))}
          </div>
    
    </div>




{rowNumber.row_background &&  <div>

    <div className='flex flex-row lg:w-56 md:w-56 sm:w-56 h-14 border border-stone-300 rounded-sm ml-4'>
        <div className='text-blue-950 font-poppins font-semibold flex items-center mx-auto pl-2 text-sm'>Contract Sl No:</div>
            <div className='w-24 h-14 bg-blue-950 rounded-sm ml-auto flex items-center justify-center overflow-hidden'>
              <span className='text-white font-semibold text-2xl font-poppins'>
                {rowNumber.row_select}
              </span>
            </div>

    </div>

  </div>}

</div>




<div className='flex lg:flex-row md:flex-row sm:flex-col'>

    <div className= 'flex lg:flex-row md:flex-row sm:flex-col lg:ml-28 md:ml-28 sm:ml-2 lg:w-[45rem] md:w-[45rem] sm:w-96 mt-0 border border-stone-300'>
    

                        <div className='flex flex-wrap lg:mt-2 md:mt-2 sm:mt-2 mb-8  lg:w-[35rem] md:w-[40rem] sm:w-96'> 
                                          <InputContract  ref={contractRef}  label='Contract No:' type='text' name='contractNo'  value={inputValue.contract_no} onChange={editInputField}/>{/* onChange={(e) => setInputValue(e.target.value)}*/}
                                          <InputContract  ref={bpRef} label='Business Partner Name:' type='text' name='bpname' value={inputValue.bp_name}  onChange={editInputField}/>
                                          <InputContract ref={contactPersonRef}  label='Contact Person' type='name' name='contactperson' value={inputValue.contact_person}  onChange={editInputField}/>
                                          <InputContract  ref={emailRef} label='Email Address' type='email' name='email' value={inputValue.email_id}  onChange={editInputField}/>
                                          <InputContract  ref={endUserRef} label='End User' type='name' name='enduser' value={inputValue.end_user}  onChange={editInputField}/>
                                          <InputContract  ref={startDateRef} label='Contract Start Date' type='date' name='startdate' value={inputValue.contract_st_dt}  onChange={editInputField}/>
                                          <InputContract  ref={endDateRef}  label='Contract Expiry Date:' type='date' name='endDate' value={inputValue.contract_end_dt}  onChange={editInputField}/>
                                          <InputContract ref={typeRef}  label='SLA Type' type='text' name='slaType' value={inputValue.sla_type}  onChange={editInputField}/>   
                                          <InputContract  ref={locationRef} label='Prime Location' type='text' name='location' value={inputValue.prime_loc}  onChange={editInputField}/> 
                                          <div className=' text-xs font-poppins pt-2 ml-4 font-semibold'>User id:
                                          <input type='text' name='user_id'  ref={userIdRef}   value={inputValue.user_id}  onChange={editInputField} className='lg:w-44 lg:h-7 md:w-44 md:h-7 sm:w-44 sm:h-8 border border-stone-300 rounded-sm pl-2 mt-6 font-poppins mb-1 text-xs'/>
                                          </div>

                                          <div className=' text-xs font-poppins pt-2 ml-4 font-semibold'>Password:
                                          <input type='text' name='user_id'  ref={passwordRef}  value={inputValue.pswd}  onChange={editInputField} className='lg:w-44 lg:h-7 md:w-44 md:h-7 sm:w-44 sm:h-8 border border-stone-300 rounded-sm pl-2 mt-6 font-poppins mb-1 text-xs'/>
                                          </div>
                                          
                                          {/* <UserSettings  onSelectStateChange={handleSelectStateChange} valueToId={inputValue.user_id}  valueToPsd={inputValue.pswd}/>  */}
                        </div>

                        <div className='w-auto h-32 flex flex-col lg:ml-0 md:ml-0 sm:ml-36'>
                                        <button className='mt-4 mr-4 lg:w-28 lg:h-8 md:w-28 md:h-8 sm:w-28 sm:h-10 bg-blue-950 rounded-md font-poppins text-xs text-white'>Edit</button>
                                        <button className='mt-4 mr-4 lg:w-28 lg:h-8 md:w-28 md:h-8 sm:w-28 sm:h-10  border border-blue-950 rounded-md font-poppins text-xs text-blue-950'>Delete</button>
                        </div>
     

    </div>

                      <div className='flex flex-col w-auto'> 
                          <BoqComponent />
                      </div>

  </div>
    

  </div>

</div>
  
    </>
  )
}

export default Contracts

