import React from 'react'
import {useSelector,useDispatch} from 'react-redux'
import editIcon from '../assets/edit.svg'
import deleteIcon from '../assets/delete.svg'

const BillOfQuantity = ({sl_no,device_type,device_sl_no,location,remarks,onDelete,onEdit}) => {
const dispatch = useDispatch()
const handleBoqEdit = ()=>{

  }

//const {device_type,device_sl_no,location,remarks} = useSelector((state)=>state.boq)
 
  return (
    <>
                            
                              <div className='flex w-auto h-7 border border-stone-200'>
                                <input readOnly value={sl_no} className='pl-1 pr-1 lg:w-12 md:w-12 sm:w-10 h-6 border-r border-stone-200 text-xs text-stone-500 text-center font-semibold'/>
                                <input readOnly value={device_type} className='pl-1 pr-1 lg:w-20 md:w-20 sm:w-[4.4rem] h-6 border-r border-stone-200 text-xs text-stone-500 text-center'/>
                                <input readOnly value={device_sl_no} className='pl-1 pr-1 lg:w-20 md:w-20 sm:w-[4.4rem] h-6 border-r border-stone-200 text-xs text-stone-500 text-center'/>
                                <input readOnly value={location} className='pl-1 pr-1 lg:w-20 md:w-20 sm:w-[4.4rem] h-6 border-r border-stone-200 text-xs text-stone-500 text-center'/>
                                <input readOnly value={remarks} className='pl-1 pr-1 lg:w-20 md:w-20 sm:w-[4.4rem] h-6 border-r border-stone-200 text-xs text-stone-500 text-center'/>
                                <div className='flex flex-row w-20 h-6 bg-white'>
                                <img src={editIcon} alt='edit' className='w-3 lg:ml-5 md:ml-5 sm:ml-4' onClick={onEdit}/>
                                <img src={deleteIcon} alt='delete' className='w-3 lg:ml-3 md:ml-3 sm:ml-2' onClick={onDelete}/>
                                </div>
                              </div>                           

    </>
  )
}

export default BillOfQuantity