import React,{createContext, useState} from "react";


const AuthContext = createContext()

const AuthProvider = ({children})=>{
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [contractEnable, setContractEnable] = useState(false)
    const [userRole, setUserRole] = useState(null);

    const login = (role) => {
       setIsAuthenticated(true);
       setUserRole(role)
    };

    const routeContract = ()=>{
        setContractEnable(true)
    }


    return (
        <AuthContext.Provider value={{isAuthenticated,userRole,contractEnable,login,routeContract}}>
            {children}
        </AuthContext.Provider>
    );

}

export { AuthProvider, AuthContext };