import {createSlice} from '@reduxjs/toolkit'

//const INITIAL_STATE = []
const INITIAL_STATE = {
    mainHeads: [],
    activateAdmin: false,
    activateTickets:false,
    activateContracts:false,
    activateBoq:false,
    activateSettings:false,
    setButton:false
  };
   
 
const adminSlice = createSlice({
    name:"admin",
    initialState:INITIAL_STATE,
    reducers:{
        setMainHeads: (state, action) => {
            state.mainHeads.push(action.payload);
          },

          deactivateAll: (state) => {
            state.activateAdmin = false;
            state.activateTickets = false;
            state.activateContracts = false;
            state.activateBoq = false;
            state.activateSettings = false;
          },
          setAdminActive: (state) => {
            state.activateAdmin = true;
          },
          setTicketsActive: (state) => {
            state.activateTickets = true;
          },
          setContractsActive: (state) => {
            state.activateContracts = true;
          },
          setBoqActive: (state) => {
            state.activateBoq =true;
          },
          setSettingsActive: (state) => {
            state.activateSettings = true;
          },

          setButtonStatus:(state,action) =>{
            state.setButton = action.payload
          }
    }
})

export const {setButtonStatus,setMainHeads,deactivateAll,setAdminActive,setTicketsActive,setContractsActive,setBoqActive,setSettingsActive} = adminSlice.actions;
export default adminSlice.reducer