import React from 'react'

const ContractHead = () => {
  return (
    <>
     <div className='flex lg:flex-row md:flex-row sm:flex-col lg:ml-28 md:ml-28 sm:ml-2 ml-28 w-auto lg:mt-1 md:m-1 sm:mt-2  border border-stone-300 shadow-lg '>
                <div className='text-xs font-semibold font-poppins text-blue-950  border border-stone-300  lg:w-10 lg:h-6 md:w-10 md:h-5 sm:w-24 sm:h-5  lg:text-center md:text-center '>Sl No:</div>
                <div className='text-xs font-semibold font-poppins text-blue-950  border border-stone-300 lg:w-14 lg:h-6 md:w-14 md:h-5 sm:w-24 sm:h-5 lg:text-center md:text-center '>Ctrt No:</div>
                <div className='text-xs font-semibold font-poppins text-blue-950  border border-stone-300 lg:w-20 lg:h-6 md:w-20 md:h-5 sm:w-24 sm:h-5 lg:text-center md:text-center '>BP Name:</div>
                <div className='text-xs font-semibold font-poppins text-blue-950  border border-stone-300 lg:w-20 lg:h-6 md:w-20 md:h-5 sm:w-24 sm:h-5 lg:text-center md:text-center '>Cnt Person</div>
                <div className='text-xs font-semibold font-poppins text-blue-950  border border-stone-300 lg:w-20 lg:h-6 md:w-20 md:h-5 sm:w-24 sm:h-5 lg:text-center md:text-center '>Email Id</div>
                <div className='text-xs font-semibold font-poppins text-blue-950  border border-stone-300 lg:w-20 lg:h-6 md:w-20 md:h-5 sm:w-24 sm:h-5 lg:text-center md:text-center '>End User</div>
                <div className='text-xs font-semibold font-poppins text-blue-950  border border-stone-300 lg:w-20 lg:h-6 md:w-20 md:h-5 sm:w-24 sm:h-5 lg:text-center md:text-center '>Contr: Strt Dt</div>
                <div className='text-xs font-semibold font-poppins text-blue-950  border border-stone-300 lg:w-24 lg:h-6 md:w-24 md:h-5 sm:w-24 sm:h-5 lg:text-center md:text-center '>Contr: Exp Dt</div>
                <div className='text-xs font-semibold font-poppins text-blue-950  border border-stone-300 lg:w-20 lg:h-6 md:w-20 md:h-5 sm:w-24 sm:h-5 lg:text-center md:text-center '>SLA Type</div>
                <div className='text-xs font-semibold font-poppins text-blue-950  border border-stone-300 lg:w-20 lg:h-6 md:w-20 md:h-5 sm:w-24 sm:h-5 lg:text-center md:text-center '>Prm: Loc:</div>
                <div className='text-xs font-semibold font-poppins text-blue-950  border border-stone-300 lg:w-14 lg:h-6 md:w-14 md:h-5 sm:w-24 sm:h-5 lg:text-center md:text-center '>User Id</div>
                <div className='text-xs font-semibold font-poppins text-blue-950  border border-stone-300 lg:w-14 lg:h-6 md:w-14 md:h-5 sm:w-24 sm:h-5 lg:text-center md:text-center '>Pswd</div>
                {/* <div className='text-xs font-semibold font-poppins text-blue-950  border border-stone-300 lg:w-14 lg:h-6 md:w-14 md:h-5 sm:w-24 sm:h-5 lg:text-center md:text-center '>edit/del</div> */}
                                                                                    
    </div>
  
    </>
  )
}

export default ContractHead

{/*     */}