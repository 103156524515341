import React,{useState,useEffect} from 'react'
import editIcon from '../assets/edit.svg'
import deleteIcon from '../assets/delete.svg'

const ContractDatas = ({sl_no,contract_no,bp_name,contact_person,email_id,end_user,start_date,end_date,sla_type,location,user_id,password,selectedRow}) => {



  const onSelect = (sl_no)=>{
    selectedRow(sl_no)
    
    //console.log(sl_no);
    
  }

  return (
    <>
      <div className={`flex lg:flex-row md:flex-row sm:flex-col lg:ml-28 md:ml-28 sm:ml-2 w-auto  border border-stone-300 cursor-default`} onClick={()=>onSelect(sl_no)}>
                <div className='text-xs font-poppins text-blue-950  border border-stone-300 lg:h-5  lg:w-10 md:h-5 md:w-20 sm:w-32 sm:h-5 text-center'>{sl_no}</div>
                <div className='text-xs font-poppins text-blue-950  border border-stone-300 lg:h-5 lg:w-14 md:h-5 md:w-14 sm:w-32 sm:h-5 text-center overflow-hidden whitespace-nowrap text-ellipsis pl-1'>{contract_no}</div>
                <div className='text-xs font-poppins text-blue-950  border border-stone-300 lg:h-5 lg:w-20 md:h-5 md:w-20 sm:w-32 sm:h-5 text-center overflow-hidden whitespace-nowrap text-ellipsis pl-1'>{bp_name}</div>
                <div className='text-xs font-poppins text-blue-950  border border-stone-300 lg:h-5 lg:w-20 md:h-5 md:w-20 sm:w-32 sm:h-5 text-center overflow-hidden whitespace-nowrap text-ellipsis pl-1'>{contact_person}</div>
                <div className='text-xs font-poppins text-blue-950  border border-stone-300 lg:h-5 lg:w-20 md:h-5 md:w-20 sm:w-32 sm:h-5 text-center overflow-hidden whitespace-nowrap text-ellipsis pl-1'>{email_id}</div>
                <div className='text-xs font-poppins text-blue-950  border border-stone-300 lg:h-5 lg:w-20 md:h-5 md:w-20 sm:w-32 sm:h-5 text-center overflow-hidden whitespace-nowrap text-ellipsis pl-1'>{end_user}</div>
                <div className='text-xs font-poppins text-blue-950  border border-stone-300 lg:h-5 lg:w-20 md:h-5 md:w-20 sm:w-32 sm:h-5 text-center overflow-hidden whitespace-nowrap text-ellipsis pl-1'>{start_date}</div>
                <div className='text-xs font-poppins text-blue-950  border border-stone-300 lg:h-5 lg:w-24 md:h-5 md:w-24 sm:w-32 sm:h-5 text-center overflow-hidden whitespace-nowrap text-ellipsis pl-1'>{end_date}</div>
                <div className='text-xs font-poppins text-blue-950  border border-stone-300 lg:h-5 lg:w-20 md:h-5 md:w-20 sm:w-32 sm:h-5 text-center overflow-hidden whitespace-nowrap text-ellipsis pl-1'>{sla_type}</div>
                <div className='text-xs font-poppins text-blue-950  border border-stone-300 lg:h-5 lg:w-20 md:h-5 md:w-20 sm:w-32 sm:h-5 text-center overflow-hidden whitespace-nowrap text-ellipsis pl-1'>{location}</div>
                <div className='text-xs font-poppins text-blue-950  border border-stone-300 lg:h-5 lg:w-14 md:h-5 md:w-14 sm:w-32 sm:h-5 text-center overflow-hidden whitespace-nowrap text-ellipsis pl-1'>{user_id}</div>
                <div className='text-xs font-poppins text-blue-950  border border-stone-300 lg:h-5 lg:w-14 md:h-5 md:w-14 sm:w-32 sm:h-5 text-center overflow-hidden whitespace-nowrap text-ellipsis pl-1'>{password}</div>
               {/* <div className='text-xs font-poppins text-blue-950  border border-stone-300 lg:h-5 lg:w-14 md:h-5 md:w-14 sm:w-32 sm:h-5 text-center flex flex-row'>
                 <img src={editIcon} alt='edit' className='w-3 lg:ml-2 md:ml-5 sm:ml-10' onClick={onEdit}/>
                <img src={deleteIcon} alt='delete' className='w-3 lg:ml-3 md:ml-3 sm:ml-2' onClick={onDelete}/> 
                </div>*/}
                                                                                    
    </div>
   
    </>
  )
}

export default ContractDatas

{/*
     
    
    */}