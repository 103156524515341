import React,{useState,useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'

import { v4 as uuidv4 } from 'uuid';
const BoqHead = ({sendData, injectData, updateData, editId}) => {

  const [formValues, setFormValues] = useState({
    device_type: "",
    device_sl_no: "",
    location: "",
    remarks: "",
});

const [editUpdate, setEditUpdate] = useState(
  {
    status:false
  })



useEffect(()=>{
if(injectData){
setFormValues(injectData)  
setEditUpdate((prev) => ({ ...prev, status: true }));

 //console.log(giveData);
  }
},[injectData])

const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues({
      ...formValues,
      [name]: value,
   });
};


    const handleSubmit = () => {
      if((formValues.device_type.trim()==="")&&(formValues.device_sl_no.trim()==="")&&(formValues.location.trim()==="")&&(formValues.remarks.trim()===""))
      return
    if(!editUpdate.status){
       sendData(formValues)
         //dispatch(setSendBoq(formValues)) // send Boq to receive globally .formValues 
    }
    else{
      setEditUpdate((prev) => ({ ...prev, status: false }));
      updateData(formValues,editId)
     // getId(editId)
    }


      setFormValues({
      device_type: "",
      device_sl_no: "",
      location: "",
      remarks: "",     
      })
  
    };


  return (
    <div className='w-96 h-60 border border-stone-200 shadow-lg'>
      <div className='flex justify-between mt-2'>
      <div className='text-[#172554] font-poppins text-xs mt-2 ml-4'>Device Type</div>
      <input type='text' name='device_type' className='mr-2 lg:w-60 lg:h-7 md:w-48 md:h-7 sm:w-64 sm:h-8 xs:w-64 xs:h-8 border border-stone-300 rounded-sm pl-2 mt-1 font-poppins mb-1 text-sm' value={formValues.device_type} onChange={handleChange}/>
      </div>

      <div className='flex justify-between'>
      <div className='text-[#172554] font-poppins text-xs mt-2 ml-4'>Device Sl No:</div>
      <input type='text' name='device_sl_no' className='mr-2 lg:w-60 lg:h-7 md:w-48 md:h-7 sm:w-64 sm:h-8 xs:w-64 xs:h-8 border border-stone-300 rounded-sm pl-2 mt-1 font-poppins mb-1 text-sm' value={formValues.device_sl_no} onChange={handleChange} />
      </div>

      <div className='flex justify-between'>
      <div className='text-[#172554] font-poppins text-xs mt-2 ml-4'>Location</div>
      <input type='text' name='location' className='mr-2 lg:w-60 lg:h-7 md:w-48 md:h-7 sm:w-64 xs:h-8 xs:w-64 sm:h-8 border border-stone-300 rounded-sm pl-2 mt-1 font-poppins mb-1 text-sm' value={formValues.location} onChange={handleChange}  />
      </div>

    <div className='flex justify-between'>
      <div className='text-[#172554] font-poppins text-xs mt-2 ml-4'>Remarks</div>
      <input type='text' name='remarks' className='mr-2 lg:w-60 lg:h-7 md:w-48 md:h-7 sm:w-64 xs:h-8 xs:w-64 sm:h-8 border border-stone-300 rounded-sm pl-2 mt-1 font-poppins mb-1 text-sm' value={formValues.remarks} onChange={handleChange}  />
    </div>
    
  <button className='mb-4 lg:w-24 lg:h-8 md:w-24 md:h-8 sm:w-28 sm:h-10 xs:w-28 xs:h-10 mt-4 ml-56 border border-blue-950 rounded-md font-poppins text-sm text-blue-950'onClick={handleSubmit}>{editUpdate.status ?'Save':'Add'}</button>
   
  </div>
  )
}

export default BoqHead