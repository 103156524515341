import React, { useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import logo from '../assets/logo-white.png'
import support from '../assets/support-white.png'
import {useSelector,useDispatch} from 'react-redux'
import axios from 'axios'


import {setButtonStatus,deactivateAll,setAdminActive,setTicketsActive,setContractsActive,setBoqActive,setSettingsActive} from '../redux/admin';
import { checkContract, setContracts } from '../redux/contracts';

  const SidePanel = () => {
  const dispatch = useDispatch()
  const {setButton, activateAdmin, activateContracts, activateBoq, activateTickets, activateSettings} =  useSelector((state)=>state.admin)
//const {setContracts} = useSelector((state)=>state.contracts)
  const navigate = useNavigate()
  const { routeContract} = useContext(AuthContext);

  
useEffect(()=>{
  if(setButton){
    handleClick('activateContracts') 
  } 
  dispatch(setButtonStatus(false))
},[setButton])


const getTotalContract = async () => {
  try {
    const response = await axios.get('https://www.therettermann.com/get_contracts.php');
    const { contract_data } = response.data;

    //console.log("This is boq fetch",contract_boq);, contract_boq
    
    //dispatch(checkContract())
    dispatch(setContracts(contract_data))
   // console.log(contract_data);
   // setTotalContract(total_contracts);
  } catch (error) {
  console.log("error",error);
   // toast.error("Contract Fetching Failed");
  }
};



    const handleClick = (section) => {
    dispatch(deactivateAll())
    routeContract()   
      switch (section) {
      case 'activateAdmin':{
       dispatch(setAdminActive());
       navigate('/admin');
        break; 
      }        
      case 'activateTickets':{
        dispatch(setTicketsActive());
        break;}
      case 'activateContracts':{
        dispatch(setContractsActive());
        getTotalContract();
        navigate('/contracts');
        
        break;
      }
      case 'activateBoq':{
        dispatch(setBoqActive());
        break;}
      case 'activateSettings':{
        dispatch(setSettingsActive());
        break;}
      default:
        break;
    }

  };


  return (
    <>
  
    <div className='fixed top-0 left-0 lg:w-28 lg:h-[100vh] md:w-28 md:h-[100vh] sm:w-full sm:h-20 xs:w-full xs:h-20 bg-blue-950 z-10 shadow-lg'>
        <div className='flex lg:ml-3 md:ml-3 sm:ml-3 xs:ml-3'>
        <div>
        <img className='w-7 mt-4' src={logo} alt='logo'/>
        </div>
        <div>
        <img className='w-14 mt-5 ml-1' src={support} alt='logo'/>
        </div>
        </div>
    <div className='lg:w-24 sm:hidden md:block lg:block'>
     <div className={`text-white font-poppins text-xs ml-2 mt-1 font-light lg:border lg:border-blue-900 md:border md:border-blue-900 rounded-sm w-24 h-6 text-left pt-1 pl-1 cursor-default ${activateAdmin && ' bg-blue-900'}`} onClick={() => handleClick('activateAdmin')}>Admin Panel</div>
     <div className={`text-white font-poppins text-xs ml-2 mt-1 font-light lg:border lg:border-blue-900 md:border md:border-blue-900 rounded-sm w-24 h-6 text-left pt-1 pl-1 cursor-default ${activateTickets && 'bg-blue-900'}`} onClick={() => handleClick('activateTickets')}>Tickets</div>
     <div className={`text-white font-poppins text-xs ml-2 mt-1 font-light lg:border lg:border-blue-900 md:border md:border-blue-900 rounded-sm w-24 h-6 text-left pt-1 pl-1 cursor-default ${activateContracts && 'bg-blue-900'}`} onClick={() => handleClick('activateContracts')}>Contracts</div>
     <div className={`text-white font-poppins text-xs ml-2 mt-1 font-light lg:border lg:border-blue-900 md:border md:border-blue-900 rounded-sm w-24 h-6 text-left pt-1 pl-1 cursor-default ${activateBoq && 'bg-blue-900'}`} onClick={() => handleClick('activateBoq')}>Bill of Quantity</div>
     <div className={`text-white font-poppins text-xs ml-2 mt-1 font-light lg:border lg:border-blue-900 md:border md:border-blue-900 rounded-sm w-24 h-6 text-left pt-1 pl-1 cursor-default ${activateSettings && 'bg-blue-900'}`} onClick={() => handleClick('activateSettings')}>Settings</div>

     </div>     
   </div>
   
    </>
  )
}

export default SidePanel