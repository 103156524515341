import { configureStore } from "@reduxjs/toolkit";
import billofqantity from "./boq";
import adminControl from "./admin"
import contractControl from "./contracts"


export const store = configureStore({
    reducer:{
       admin:adminControl,
       contracts:contractControl,
       boq:billofqantity
    }
})